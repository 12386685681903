/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function ($) {
    var isHome = $('body.home').length > 0;

    var wrapEle = '.wrap';
    var mdHeaderEle = '#header-sticky-md';
    var headerclassName = isHome ? 'navbar-fixed-top' : 'navbar-fixed-top animated slideInDown';


    var directlyDisplayStickyTopNav = false;

    function checkForStickyNav(topThresold) {
        if ($(window).scrollTop() > topThresold) {
            $(mdHeaderEle).addClass(headerclassName);
        }
        else {
            $(mdHeaderEle).removeClass(headerclassName);
        }
    }





    function offset(el) {
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }

    /**
     * content slide on scroll
     * https://css-tricks.com/slide-in-as-you-scroll-down-boxes/
     */
    (function($) {
        $.fn.visible = function(partial) {
            var $t            = $(this),
                $w            = $(window),
                viewTop       = $w.scrollTop(),
                viewBottom    = viewTop + $w.height(),
                _top          = $t.offset().top;
            return (viewBottom >= _top);
        };
    })($);

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {

        // All pages
        'common': {
            init: function () {

                new WOW().init();

                var topThresold = 0;
                $(window).scroll(function () {
                    topThresold = isHome ? $(wrapEle).position().top - 90 : 125 + 300;
                    topThresold = directlyDisplayStickyTopNav ? -1 : topThresold;
                    checkForStickyNav(topThresold);

                });

                // do the initial
                topThresold = isHome ? $(wrapEle).position().top - 90 : 125 + 300;
                topThresold = directlyDisplayStickyTopNav ? -1 : topThresold;
                checkForStickyNav(topThresold);

                // Toggle mobile nav
                var navTrigger = document.getElementsByClassName('js-nav-trigger')[0],
                    navTriggerFixed = document.getElementsByClassName('js-nav-trigger')[1],
                    body = document.getElementsByTagName('body')[0];
                if(navTrigger) {
                    navTrigger.addEventListener('click', toggleNavigation);
                }
                if(navTriggerFixed) {
                    navTriggerFixed.addEventListener('click', toggleNavigation);
                }
                function toggleNavigation(event) {
                  event.preventDefault();
                  body.classList.toggle('nav-open');
                  if( isHome ) {
                    $('html,body').animate(
                        { scrollTop: $(mdHeaderEle).offset().top },
                        { duration: 500, easing:'easeOutCubic'}
                    );
                  }
                }

                $('.site-navigation ul.sub-menu').each(function(){
                    $(this).addClass("dropdown-menu");
                });

                $('.site-navigation .menu-item-has-children a').dropdownHover({
                    delay: 300,
                    instantlyCloseOthers: false
                });

                $('form.mc-form').ajaxChimp();


                $('a.subscribe-trigger').click(function() {
                    event.preventDefault();
                    $("#popup-subscribe").fadeIn('slow');
                });

                $('#close-subscribe').click(function() {
                    event.preventDefault();
                    $("#popup-subscribe").fadeOut('slow');
                });


                $('a#back-to-top').click(function () {
                    event.preventDefault();
                    $('html, body').animate({scrollTop : 0},1000);
                });

                $('.activate-search').click(function(){
                    event.preventDefault();
                    $("#search-container").toggleClass('active');
                    $('#search').focus();
                });

                $('#search').focusout(function(){
                    event.preventDefault();
                    $("#search-container").toggleClass('active');
                });

                $('.hoverable').mouseenter(function () {
                    $(this).find('.download').fadeTo(0, .85);

                }).mouseleave(function () {
                    $(this).find('.download').fadeTo(0, 0);
                });

                $(".carousel").swiperight(function() {
                    $(this).carousel('prev');
                });
                $(".carousel").swipeleft(function() {
                    $(this).carousel('next');
                });

                $('.grid').imagesLoaded(function () {
                    function comparatorOrder(a, b) {
                        return $(a).data('order') < $(b).data('order') ? -1 : 1;
                    }

                    var wookmark = new Wookmark('.grids', {
                        autoResize: true,
                        offset: 20, // Optional, the distance between grid items
                        outerOffset: 0, // Optional, the distance to the containers border
                        itemWidth: 350, // Optional, the width of a grid item
                        comparator: comparatorOrder
                    });
                });

            },
            finalize: function () {


            }
        },
        // Home page
        'home': {
            init: function () {
                var mainMenu = $('.site-navigation .menu');
                var dropDownMenu = $('.site-navigation ul.dropdown-menu');

                function adjustDropdownPosition() {
                    var documentWidth = $(window).width();
                    var divOffset = offset(mainMenu[0]);

                    $(dropDownMenu).css('min-width', documentWidth + 'px');
                    $(dropDownMenu).css('margin-left', divOffset.left * -1 + 'px');

                }
                $(window).resize(function () {
                    adjustDropdownPosition();
                });

                adjustDropdownPosition();

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        },
        'the_story': {
            init: function () {

                $(".carousel").click(function() {
                    $(this).carousel('next');
                });

            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
